<template>
	<v-container>
		<v-form ref="ruleForm">
			<v-radio-group
				row
				v-model="type">
				<v-radio
					label="One time"
					:value="OFFER_RULE_MODES.ONCE"></v-radio>
				<v-radio
					label="Recurring"
					:value="OFFER_RULE_MODES.RECURRING"></v-radio>
			</v-radio-group>

			<template>
				<v-switch
					inset
					v-model="allDaysSelected"
					class="pb-0 mb-0"
					:label="allDaysSelected ? `Deselect all days` : `Select all days`"
					key="trans-select-all"
					@click="toggleDays"></v-switch>
				<v-row key="trans-row">
					<!-- <v-chip-group multiple column class="ml-3" color="#ffffff">
						<v-chip
							key="trans-select-all"
							:class="{
								secondary: allDaysSelected,
								primary: !allDaysSelected,
							}"
							@click="toggleDays"
						>
							{{ allDaysSelected ? "Deselect all" : "Select all" }}
						</v-chip>
					</v-chip-group> -->

					<v-chip-group
						key="trans-chip-group"
						multiple
						v-model="which_days"
						column
						class="ml-3 mt-0 pt-0"
						:class="{ shake: !daysValid }"
						color="#ffffff">
						<v-chip
							v-for="day in days"
							:key="day"
							:value="day"
							:class="[
								{
									secondary: !which_days.includes(day),
									primary: which_days.includes(day),
								},
								daysValid ? '' : 'error--text',
							]">
							{{ day }}
						</v-chip>
					</v-chip-group>
					<!-- <v-divider vertical class="mx-4" key="trans-divider"></v-divider> -->
				</v-row>
				<v-row>
					<v-slide-y-transition mode="out-in">
						<span
							v-if="!daysValid"
							class="ml-4 v-messages error--text">
							Must be filled
						</span>
					</v-slide-y-transition>
				</v-row>
			</template>

			<v-row>
				<v-col
					cols="12"
					md="6">
					<v-menu
						ref="menuFrom"
						v-model="timePickerFromOpen"
						:close-on-content-click="false"
						:return-value.sync="valid_from_within_day"
						transition="scale-transition"
						:nudge-bottom="50"
						max-width="290px"
						min-width="290px">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="valid_from_within_day"
								label="Valid hour from:"
								prepend-icon="mdi-clock-time-four-outline"
								readonly
								v-bind="attrs"
								v-on="on"></v-text-field>
						</template>
						<v-time-picker
							format="24hr"
							class="ma-0"
							v-if="timePickerFromOpen"
							v-model="valid_from_within_day"
							full-width
							:max="valid_to_within_day"
							@click:minute="
								$refs.menuFrom.save(valid_from_within_day)
							"></v-time-picker>
					</v-menu>
				</v-col>
				<v-col
					cols="12"
					md="6">
					<v-menu
						ref="menuTo"
						v-model="timePickerToOpen"
						:close-on-content-click="false"
						:nudge-bottom="50"
						:return-value.sync="valid_to_within_day"
						transition="scale-transition">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="valid_to_within_day"
								label="Valid hour to:"
								prepend-icon="mdi-clock-time-four-outline"
								readonly
								v-bind="attrs"
								v-on="on"></v-text-field>
						</template>
						<v-time-picker
							format="24hr"
							class="ma-0"
							v-if="timePickerToOpen"
							:min="valid_from_within_day"
							v-model="valid_to_within_day"
							@click:minute="
								$refs.menuTo.save(valid_to_within_day)
							"></v-time-picker>
					</v-menu>
				</v-col>
			</v-row>
			<!-- <v-time-picker v-model="hoursFrom" format="24hr"></v-time-picker> -->
			<!-- <v-time-picker v-model="hoursTo" format="24hr"></v-time-picker> -->
			<!-- <v-divider></v-divider> -->
			<v-radio-group
				@change="onActionChange"
				row
				v-model="action">
				<v-radio
					label="Round Count"
					:value="OFFER_RULE_ACTIONS.ROUNDS"></v-radio>
				<v-radio
					label="Session Start"
					:value="OFFER_RULE_ACTIONS.SESSIONS"></v-radio>
				<v-radio
					label="Bet Amount"
					:value="OFFER_RULE_ACTIONS.BET"></v-radio>
				<v-radio
					label="Amount Lost"
					:value="OFFER_RULE_ACTIONS.LOOSE"></v-radio>
			</v-radio-group>
			<v-row>
				<v-col
					cols="12"
					md="6">
					<v-text-field
						v-model.number="limit"
						@input="onLimitChange"
						:min="1"
						:max="2147483646"
						type="number"
						:rules="numberRules"
						:label="amountLabel"></v-text-field>
				</v-col>

				<v-col
					cols="12"
					md="6">
					<v-slide-x-transition>
						<v-autocomplete
							v-if="
								action == OFFER_RULE_ACTIONS.BET ||
								action == OFFER_RULE_ACTIONS.LOOSE
							"
							prepend-icon="mdi-cash-multiple"
							v-model="currency"
							:items="currencyCodes"
							label="Currency"
							persistent-hint
							hide-selected
							:menu-props="{ offsetY: true }"></v-autocomplete>
					</v-slide-x-transition>
				</v-col>
			</v-row>
			<!-- <v-divider></v-divider> -->
			<v-checkbox
				v-model="showToPlayer"
				label="Show to player"></v-checkbox>
			<v-slide-y-transition>
				<v-textarea
					v-if="showToPlayer"
					name="input-7-1"
					label="Description"
					auto-grow
					:rules="descRules"
					@input="onDescChange"
					@click="onDescChange"
					hint="Rule description shown to player"
					v-model="description"
					rows="1"></v-textarea>
			</v-slide-y-transition>
			<!-- <v-text-field
			v-model="currency"
			
		></v-text-field> -->
		</v-form>
	</v-container>
</template>
<script>
import {
	OFFER_RULE_ACTIONS,
	OFFER_RULE_MODES,
} from "../../constants/constants";
import { currencyCodes } from "../../constants/currencyCodes";
export default {
	model: {
		event: "change",
		prop: "editRuleConfig",
	},
	props: {
		editRuleConfig: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	data() {
		return {
			daysValid: true,
			numberRules: [],
			descRules: [],
			valid: true,
			OFFER_RULE_ACTIONS,
			OFFER_RULE_MODES,
			currencyCodes,
			roundCount: null,
			sessionStart: null,
			betAmount: null,
			lostAmount: null,
			timePickerFromOpen: false,
			timePickerToOpen: false,
			days: [
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
				"Saturday",
				"Sunday",
			],
			lastSelectedDays: [],
			lastSelectedCurrency: undefined,
			lastDecription: "",
		};
	},
	computed: {
		showToPlayer: {
			get() {
				return !this.editRuleConfig?.hide_from_player ?? true;
			},
			set(val) {
				const res = { ...this.editRuleConfig };
				res.hide_from_player = !val;
				if (res.hide_from_player) {
					this.lastDecription = res.description;
				} else {
					res.description = this.lastDecription;
				}
				this.$emit("change", res);
			},
		},
		type: {
			get() {
				return this.editRuleConfig?.mode?.type;
			},
			set(val) {
				const res = { ...this.editRuleConfig };
				if (!res.mode) res.mode = {};
				res.mode.type = val;

				this.$emit("change", res);
			},
		},
		which_days: {
			get() {
				return this.editRuleConfig?.mode?.which_days ?? [];
			},
			set(val) {
				this.daysValid = true;
				const res = { ...this.editRuleConfig };
				if (!res.mode) res.mode = {};
				res.mode.which_days = val;
				this.$emit("change", res);
			},
		},
		valid_from_within_day: {
			get() {
				return this.editRuleConfig?.mode?.valid_from_within_day;
			},
			set(val) {
				const res = { ...this.editRuleConfig };
				if (!res.mode) res.mode = {};
				res.mode.valid_from_within_day = val;
				this.$emit("change", res);
			},
		},
		valid_to_within_day: {
			get() {
				return this.editRuleConfig?.mode?.valid_to_within_day;
			},
			set(val) {
				const res = { ...this.editRuleConfig };
				if (!res.mode) res.mode = {};
				res.mode.valid_to_within_day = val;
				this.$emit("change", res);
			},
		},
		action: {
			get() {
				return this.editRuleConfig?.condition?.action;
			},
			set(val) {
				const res = { ...this.editRuleConfig };
				if (!res.condition) res.condition = {};
				res.condition.action = val;
				if (
					val === OFFER_RULE_ACTIONS.BET ||
					val === OFFER_RULE_ACTIONS.LOOSE
				) {
					res.condition.currency = this.lastSelectedCurrency;
				} else {
					this.lastSelectedCurrency = res.condition.currency;
					delete res.condition.currency;
				}
				this.$emit("change", res);
			},
		},
		limit: {
			get() {
				return this.editRuleConfig?.condition?.limit;
			},
			set(val) {
				const res = { ...this.editRuleConfig };
				if (!res.condition) res.condition = {};
				res.condition.limit = val;
				this.$emit("change", res);
			},
		},
		currency: {
			get() {
				return this.editRuleConfig?.condition?.currency;
			},
			set(val) {
				const res = { ...this.editRuleConfig };
				if (!res.condition) res.condition = {};
				this.lastSelectedCurrency = val;
				res.condition.currency = val;
				this.$emit("change", res);
			},
		},
		description: {
			get() {
				return this.editRuleConfig?.description;
			},
			set(val) {
				const res = { ...this.editRuleConfig };
				res.description = val;
				this.$emit("change", res);
			},
		},
		amountLabel() {
			switch (this.action) {
				case OFFER_RULE_ACTIONS.ROUNDS:
				case OFFER_RULE_ACTIONS.SESSIONS:
					return "Count";
				case OFFER_RULE_ACTIONS.BET:
				case OFFER_RULE_ACTIONS.LOOSE:
					return "Amount";
			}
			return "";
		},
		allDaysSelected() {
			return this.which_days.length === 7;
		},
	},
	methods: {
		async submitHandler() {
			//MAKE WORK WITH BE
			if (
				this.which_days.length === 0 &&
				this.action === OFFER_RULE_ACTIONS.SESSIONS &&
				this.type === OFFER_RULE_MODES.RECURRING
			) {
				this.daysValid = false;
			}
			(this.descRules = [
				(e) => {
					if (this.showToPlayer == true && e == "") {
						this.valid = false;
						return "Description can not be empty";
					}
					return true;
				},
			]),
				(this.numberRules = [
					(e) => {
						if (e > 2147483646) {
							this.valid = false;
							return "Number must be less than 2147483647";
						}
						if (e < 1) {
							this.valid = false;
							return "Number must be positive";
						}
						if (
							this.action !== OFFER_RULE_ACTIONS.ROUNDS &&
							this.action !== OFFER_RULE_ACTIONS.SESSIONS
						)
							return true;
						if (e % 1 > 0) {
							this.valid = false;
							return "Must be a whole number";
						}
						return true;
					},
				]);
			await this.$nextTick();
			if (this.$refs.ruleForm.validate() && this.daysValid) {
				return true;
			}
			return false;
		},
		reset() {
			this.roundCount = 50;
			this.sessionStart = 1;
			this.betAmount = 100;
			this.lostAmount = 100;
		},
		onDescChange(e) {
			this.descRules = [];
		},
		onActionChange(e) {
			switch (e) {
				case OFFER_RULE_ACTIONS.ROUNDS:
					this.limit = this.roundCount;
					break;
				case OFFER_RULE_ACTIONS.SESSIONS:
					this.limit = this.sessionStart;
					break;
				case OFFER_RULE_ACTIONS.BET:
					this.limit = this.betAmount;
					break;
				case OFFER_RULE_ACTIONS.LOOSE:
					this.limit = this.lostAmount;
			}
		},
		onLimitChange(e) {
			this.numberRules = [];
			if (typeof e == "number")
				switch (this.action) {
					case OFFER_RULE_ACTIONS.ROUNDS:
						this.roundCount = e;
						break;
					case OFFER_RULE_ACTIONS.SESSIONS:
						this.sessionStart = e;
						break;
					case OFFER_RULE_ACTIONS.BET:
						this.sessionStart = e;
						break;
					case OFFER_RULE_ACTIONS.LOOSE:
						this.sessionStart = e;
				}
		},
		toggleDays() {
			if (this.allDaysSelected) {
				this.which_days = [];
				return;
			}
			this.which_days = [...this.days];
		},
		defaultValue() {
			let filledDefault = { ...this.editRuleConfig };
			let emit = false;
			if (filledDefault === null || filledDefault === undefined) {
				filledDefault = {};
				emit = true;
			}
			if (
				filledDefault.description === null ||
				filledDefault.description === undefined
			) {
				filledDefault.description = "";
				emit = true;
			}
			if (filledDefault.hide_from_player == undefined) {
				filledDefault.hide_from_player = false;
				emit = true;
			}
			if (!filledDefault.mode) {
				filledDefault.mode = {};
				emit = true;
			}
			if (!filledDefault.mode.type) {
				filledDefault.mode.type = OFFER_RULE_MODES.ONCE;
				emit = true;
			}
			if (!filledDefault.mode.valid_from_within_day) {
				filledDefault.mode.valid_from_within_day = "00:00";
				emit = true;
			}
			if (!filledDefault.mode.valid_to_within_day) {
				filledDefault.mode.valid_to_within_day = "23:59";
				emit = true;
			}
			if (!filledDefault.condition) {
				filledDefault.condition = {};
				emit = true;
			}
			if (!filledDefault.condition.action) {
				filledDefault.condition.action = OFFER_RULE_ACTIONS.SESSIONS;
				emit = true;
			}
			this.lastSelectedCurrency = filledDefault.condition.currency ?? "EUR";
			this.roundCount = this.roundCount ?? 50;
			this.sessionStart = this.sessionStart ?? 1;
			this.betAmount = this.betAmount ?? 100;
			this.lostAmount = this.lostAmount ?? 100;

			if (!filledDefault.condition.limit) {
				filledDefault.condition.limit = 1;
				emit = true;
			} else {
				const mem = filledDefault.condition.limit;
				switch (filledDefault.condition.action) {
					case OFFER_RULE_ACTIONS.BET:
						this.betAmount = mem;
						break;
					case OFFER_RULE_ACTIONS.LOOSE:
						this.lostAmount = mem;
						break;
					case OFFER_RULE_ACTIONS.ROUNDS:
						this.roundCount = mem;
						break;
					case OFFER_RULE_ACTIONS.SESSIONS:
						this.sessionStart = mem;
						break;
				}
			}
			if (emit) {
				this.$emit("change", filledDefault);
			}
		},
	},
	created() {
		this.defaultValue();
	},
	beforeUpdate() {
		this.defaultValue();
	},
};
</script>

<style>
.shake {
	animation: shake 0.3s cubic-bezier(0.25, 0.8, 0.5, 1) both;
	transform: translate3d(0, 0, 0);
}

@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}
	20%,
	80% {
		transform: translate3d(1px, 0, 0);
	}
	30%,
	70% {
		transform: translate3d(-2px, 0, 0);
	}
	40%,
	50%,
	60% {
		transform: translate3d(2px, 0, 0);
	}
}
</style>
