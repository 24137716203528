var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-form",
        { ref: "ruleForm" },
        [
          _c(
            "v-radio-group",
            {
              attrs: { row: "" },
              model: {
                value: _vm.type,
                callback: function ($$v) {
                  _vm.type = $$v
                },
                expression: "type",
              },
            },
            [
              _c("v-radio", {
                attrs: { label: "One time", value: _vm.OFFER_RULE_MODES.ONCE },
              }),
              _c("v-radio", {
                attrs: {
                  label: "Recurring",
                  value: _vm.OFFER_RULE_MODES.RECURRING,
                },
              }),
            ],
            1
          ),
          [
            _c("v-switch", {
              key: "trans-select-all",
              staticClass: "pb-0 mb-0",
              attrs: {
                inset: "",
                label: _vm.allDaysSelected
                  ? `Deselect all days`
                  : `Select all days`,
              },
              on: { click: _vm.toggleDays },
              model: {
                value: _vm.allDaysSelected,
                callback: function ($$v) {
                  _vm.allDaysSelected = $$v
                },
                expression: "allDaysSelected",
              },
            }),
            _c(
              "v-row",
              { key: "trans-row" },
              [
                _c(
                  "v-chip-group",
                  {
                    key: "trans-chip-group",
                    staticClass: "ml-3 mt-0 pt-0",
                    class: { shake: !_vm.daysValid },
                    attrs: { multiple: "", column: "", color: "#ffffff" },
                    model: {
                      value: _vm.which_days,
                      callback: function ($$v) {
                        _vm.which_days = $$v
                      },
                      expression: "which_days",
                    },
                  },
                  _vm._l(_vm.days, function (day) {
                    return _c(
                      "v-chip",
                      {
                        key: day,
                        class: [
                          {
                            secondary: !_vm.which_days.includes(day),
                            primary: _vm.which_days.includes(day),
                          },
                          _vm.daysValid ? "" : "error--text",
                        ],
                        attrs: { value: day },
                      },
                      [_vm._v(" " + _vm._s(day) + " ")]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c("v-slide-y-transition", { attrs: { mode: "out-in" } }, [
                  !_vm.daysValid
                    ? _c(
                        "span",
                        { staticClass: "ml-4 v-messages error--text" },
                        [_vm._v(" Must be filled ")]
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ],
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-menu",
                    {
                      ref: "menuFrom",
                      attrs: {
                        "close-on-content-click": false,
                        "return-value": _vm.valid_from_within_day,
                        transition: "scale-transition",
                        "nudge-bottom": 50,
                        "max-width": "290px",
                        "min-width": "290px",
                      },
                      on: {
                        "update:returnValue": function ($event) {
                          _vm.valid_from_within_day = $event
                        },
                        "update:return-value": function ($event) {
                          _vm.valid_from_within_day = $event
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        label: "Valid hour from:",
                                        "prepend-icon":
                                          "mdi-clock-time-four-outline",
                                        readonly: "",
                                      },
                                      model: {
                                        value: _vm.valid_from_within_day,
                                        callback: function ($$v) {
                                          _vm.valid_from_within_day = $$v
                                        },
                                        expression: "valid_from_within_day",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.timePickerFromOpen,
                        callback: function ($$v) {
                          _vm.timePickerFromOpen = $$v
                        },
                        expression: "timePickerFromOpen",
                      },
                    },
                    [
                      _vm.timePickerFromOpen
                        ? _c("v-time-picker", {
                            staticClass: "ma-0",
                            attrs: {
                              format: "24hr",
                              "full-width": "",
                              max: _vm.valid_to_within_day,
                            },
                            on: {
                              "click:minute": function ($event) {
                                return _vm.$refs.menuFrom.save(
                                  _vm.valid_from_within_day
                                )
                              },
                            },
                            model: {
                              value: _vm.valid_from_within_day,
                              callback: function ($$v) {
                                _vm.valid_from_within_day = $$v
                              },
                              expression: "valid_from_within_day",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-menu",
                    {
                      ref: "menuTo",
                      attrs: {
                        "close-on-content-click": false,
                        "nudge-bottom": 50,
                        "return-value": _vm.valid_to_within_day,
                        transition: "scale-transition",
                      },
                      on: {
                        "update:returnValue": function ($event) {
                          _vm.valid_to_within_day = $event
                        },
                        "update:return-value": function ($event) {
                          _vm.valid_to_within_day = $event
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        label: "Valid hour to:",
                                        "prepend-icon":
                                          "mdi-clock-time-four-outline",
                                        readonly: "",
                                      },
                                      model: {
                                        value: _vm.valid_to_within_day,
                                        callback: function ($$v) {
                                          _vm.valid_to_within_day = $$v
                                        },
                                        expression: "valid_to_within_day",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.timePickerToOpen,
                        callback: function ($$v) {
                          _vm.timePickerToOpen = $$v
                        },
                        expression: "timePickerToOpen",
                      },
                    },
                    [
                      _vm.timePickerToOpen
                        ? _c("v-time-picker", {
                            staticClass: "ma-0",
                            attrs: {
                              format: "24hr",
                              min: _vm.valid_from_within_day,
                            },
                            on: {
                              "click:minute": function ($event) {
                                return _vm.$refs.menuTo.save(
                                  _vm.valid_to_within_day
                                )
                              },
                            },
                            model: {
                              value: _vm.valid_to_within_day,
                              callback: function ($$v) {
                                _vm.valid_to_within_day = $$v
                              },
                              expression: "valid_to_within_day",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-radio-group",
            {
              attrs: { row: "" },
              on: { change: _vm.onActionChange },
              model: {
                value: _vm.action,
                callback: function ($$v) {
                  _vm.action = $$v
                },
                expression: "action",
              },
            },
            [
              _c("v-radio", {
                attrs: {
                  label: "Round Count",
                  value: _vm.OFFER_RULE_ACTIONS.ROUNDS,
                },
              }),
              _c("v-radio", {
                attrs: {
                  label: "Session Start",
                  value: _vm.OFFER_RULE_ACTIONS.SESSIONS,
                },
              }),
              _c("v-radio", {
                attrs: {
                  label: "Bet Amount",
                  value: _vm.OFFER_RULE_ACTIONS.BET,
                },
              }),
              _c("v-radio", {
                attrs: {
                  label: "Amount Lost",
                  value: _vm.OFFER_RULE_ACTIONS.LOOSE,
                },
              }),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      min: 1,
                      max: 2147483646,
                      type: "number",
                      rules: _vm.numberRules,
                      label: _vm.amountLabel,
                    },
                    on: { input: _vm.onLimitChange },
                    model: {
                      value: _vm.limit,
                      callback: function ($$v) {
                        _vm.limit = _vm._n($$v)
                      },
                      expression: "limit",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-slide-x-transition",
                    [
                      _vm.action == _vm.OFFER_RULE_ACTIONS.BET ||
                      _vm.action == _vm.OFFER_RULE_ACTIONS.LOOSE
                        ? _c("v-autocomplete", {
                            attrs: {
                              "prepend-icon": "mdi-cash-multiple",
                              items: _vm.currencyCodes,
                              label: "Currency",
                              "persistent-hint": "",
                              "hide-selected": "",
                              "menu-props": { offsetY: true },
                            },
                            model: {
                              value: _vm.currency,
                              callback: function ($$v) {
                                _vm.currency = $$v
                              },
                              expression: "currency",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-checkbox", {
            attrs: { label: "Show to player" },
            model: {
              value: _vm.showToPlayer,
              callback: function ($$v) {
                _vm.showToPlayer = $$v
              },
              expression: "showToPlayer",
            },
          }),
          _c(
            "v-slide-y-transition",
            [
              _vm.showToPlayer
                ? _c("v-textarea", {
                    attrs: {
                      name: "input-7-1",
                      label: "Description",
                      "auto-grow": "",
                      rules: _vm.descRules,
                      hint: "Rule description shown to player",
                      rows: "1",
                    },
                    on: { input: _vm.onDescChange, click: _vm.onDescChange },
                    model: {
                      value: _vm.description,
                      callback: function ($$v) {
                        _vm.description = $$v
                      },
                      expression: "description",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }