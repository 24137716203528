import { render, staticRenderFns } from "./RuleEdit.vue?vue&type=template&id=da82000c"
import script from "./RuleEdit.vue?vue&type=script&lang=js"
export * from "./RuleEdit.vue?vue&type=script&lang=js"
import style0 from "./RuleEdit.vue?vue&type=style&index=0&id=da82000c&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/logan2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('da82000c')) {
      api.createRecord('da82000c', component.options)
    } else {
      api.reload('da82000c', component.options)
    }
    module.hot.accept("./RuleEdit.vue?vue&type=template&id=da82000c", function () {
      api.rerender('da82000c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/freeGames/RuleEdit.vue"
export default component.exports